import "../css/fonts.css"
import "../css/style.scss"
import "../css/responsive.scss"
import React, { Component } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby';
import DefaultTemplate from "../templates/default"
import arrowLeft from '../images/black-arrow.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

class Disclaimer extends Component {

    componentDidMount(){
        AOS.init({
            once: true
        })
    }

    render() {
        return <StaticQuery query={graphql`
    {
         wordpressPage(slug: { eq: "disclaimer" }){
            content
        }
    }
    `} render={props => (
        <DefaultTemplate>
            <div className="basic-pages">
                <div className="frame">
                    <Link to="/"><img className="arrow" src={ arrowLeft } alt="" /></Link>
                    <div class="basics-page-title">Disclaimer</div>
                    <div className="basic-pages-content" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.content }} />
                </div>
            </div>  
        </DefaultTemplate>
    ) } />
    }

}
   
export default Disclaimer
   